.post-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 10px 40px
}

#post { 
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.post-container h1{
    margin-bottom: 70px;
}

.answer-container {
    display: none;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
}

.coment-status {
    padding-top: 10px;
}

#answer {
    height: 40px;
    padding: 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 50%;
    font-size: 13px;
}

#answer:focus {
    outline: none;
}

.btn-form-answer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 10%;
    margin-top: 10px;
    padding: 8px 0;
    background-color: rgb(10, 94, 81);
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-weight: bold;
}

.btn-form-answer:hover {
    outline: none;
    background-color: rgb(8, 77, 66);
    cursor: pointer;
}

.btn-form-answer:focus {
    outline: none;
}

#confirm-answer {
    display: none;
}

.comments-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
}

.btn-comments-container {
    display: flex;
}

.icon-comment-container {
    color: #FFF;
    size: 14px;
    cursor: pointer;
    height: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #0A5E51;
}

.comment-icon:hover {
    color: #109783
}

.like-icon:hover {
    color: #dd3838
}

.trash-icon:hover {
    color: #0c0101
}