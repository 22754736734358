#modal-container-gallery-title {
    display: none;
    flex: 1;
    position: absolute;
    padding-top: 10%;
    justify-content: center;
    align-items: flex-start;
    background-color:  rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 130%;
    z-index: 2;
}

.modal-content-gallery-title {
    display: flex;
    flex: 1;
    max-height: 68%;
    overflow: auto;
    flex-direction: column;
    background-color: #FFF;
    margin: 0 30px;
    box-shadow: 0px 2px 8px #888888;
}

.modal-content-gallery-title .header-modal-gallery-title {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.modal-content-gallery-title ul {
    list-style-type: none;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-wrap: wrap;
}

.modal-content-gallery-title ul li {
    display: flex;
    width: 300px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    margin: 30px;
}