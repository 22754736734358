@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
#login-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-direction: column;
    background: #FFF;
    margin-top: 0px;
    border-radius: 4px;
    padding: 30px;
}

.form-login-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0A5E51;
    padding: 4% 10%;
    margin-top: 3%;
    border-radius: 5px;
}

.content button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.content button:hover {
    outline: none;
}

.content form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding-top: 30px;
}
  
.content form label {
    font-size: 14px;
    color: #FFF;
    font-weight: bold;
    margin-bottom: 8px;
}
  
.content form input {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 2px;
    height: 45px;
    padding: 0 15px;
    font-size: 16px;
    text-align: left;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}

.content form input:focus {
    outline: none;
}
  
.content button.btn {
    border-radius: 5px;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
}

.errorMessage {
    text-align: center;
    margin-bottom: 10px;
    color: #FFF;
    font-size: 16px;
}


#login-container img {
    width: 120px;
    padding-bottom: 10px;
}

#login-container form input {
    width: 400px;
    height: 40px;
}

#login-container form .btn {
    background-color: #0A5E51;
    border: 3px solid #7eefdd;;
    color: #7eefdd;
    border-radius: 3px;
}

#login-container form .btn:hover {
    background-color: #7eefdd;
    color: #000;
}

#errorLogin{
    display: none;
}
#modal-container-gallery-title {
    display: none;
    flex: 1 1;
    position: absolute;
    padding-top: 10%;
    justify-content: center;
    align-items: flex-start;
    background-color:  rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 130%;
    z-index: 2;
}

.modal-content-gallery-title {
    display: flex;
    flex: 1 1;
    max-height: 68%;
    overflow: auto;
    flex-direction: column;
    background-color: #FFF;
    margin: 0 30px;
    box-shadow: 0px 2px 8px #888888;
}

.modal-content-gallery-title .header-modal-gallery-title {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.modal-content-gallery-title ul {
    list-style-type: none;
    display: flex;
    flex: 1 1;
    justify-content: center;
    flex-wrap: wrap;
}

.modal-content-gallery-title ul li {
    display: flex;
    width: 300px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    margin: 30px;
}
.jodit_container .jodit_workplace,
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar,
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_toolbar_btn-separator,
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_toolbar_btn-break {
    border-color: #ffffff;
}
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar {
    background-color: #0a5e51;
}
.jodit_container .jodit_icon,
.jodit_container .jodit_toolbar .jodit_toolbar_btn>a {
    fill: #ffffff;
    color: #ffffff;
}
.jodit_container {
    background-color: #ffffff;
}
.jodit_container .jodit_wysiwyg {
    color: #000000;
}
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_with_dropdownlist .jodit_with_dropdownlist-trigger {
    border-top-color: #ffffff;
}
.jodit_container .jodit_placeholder {
    color: #00000080;
}

.jodit_toolbar .jodit_toolbar_btn {
    background-color: #0a5e51;
}

.jodit_form-options {
    display: none;
}

label[ref="nofollow_checkbox_box"] {
    display: none;
}


.main-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin: 30px;
}

.main-container .blog-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
}

.image-title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 300px;
    height: 200px;
    border: 1px dashed #DDD;
    background-repeat: no-repeat;
}

.dragable {
    cursor: -webkit-grab;
    cursor: grab;
}

.grabbing { 
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.image-title span {
    color: #999;
    font-size: 12px;
}

.main-container .blog-form label {
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
}

.main-container .blog-form .title {
    width: 50%;
    padding: 10px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.main-container .blog-form .title:focus  {
    outline: none;
}

.btn {
    background-color: #0A5E51;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 2px;
}

.btn:hover {
    background-color: rgb(8, 73, 63);
}

#form-header {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#form-header .header-section {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 250px;
}

.thumbnail-title {
    display: flex;
}

#center-image-button {
    display: none;
    justify-content: center;
    align-items: center;
    margin: 7px;
    height: 35px;
    border: none;
	cursor: pointer;
    outline: inherit;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    background-color: #0A5E51;
}

.center-image-button-container {
    display: flex;
    align-content: center;
    justify-content: center;
}

#resize-image-container {
    display: none;
    flex-direction: column;
    padding: 10px;
    height: 200px;
    justify-content: space-evenly;
    align-items: center;
}

.resize-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resize-icons-container {
    display: flex;
    flex-direction: column;
}

.resize-icon-button {
    margin: 0;
    padding: 5;
    color: green;
    font-size: 16px;
    background: none;
    border: none;
	cursor: pointer;
	outline: inherit;
}

.resize-icon-button-minus {
    color: red;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DDD;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F9F9F9;
    width: 45%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.dropzone span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    font-size: 11px;
    color: #99999F
}

.titleImage {
   align-self: flex-end;
   margin-bottom: 10px;
   color: #FFF
}

.button-container {
    display: flex;
    width: 100%;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
}

#uploadError{
    color: red;
    display: none;
}

.main-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 25px;
    margin-left: 25px;
    background-color: #0A5E51;
}

.main-header {
    display: flex;
    flex: 1 1;
    width: 100%;
    justify-content: flex-end;
}

.public-buttons-container {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
#modal-container {
    display: none;
    flex: 1 1;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color:  rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.modal-content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: #FFF;
    margin: 0px 35%;
    padding: 10px 20px;
    box-shadow: 0px 2px 8px #888888;
}

#modal-container p {
    border-bottom: 1px solid rgb(196, 186, 186);
    padding-bottom: 10px;
}

.button-content-modal {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}

.button-content-modal button {
    border: 1px solid #000;
    background: transparent;
    border-radius: 4px;
    font-weight: bold;
    color: #FFF;
    width: 45%;
    height: 40px;
    cursor: pointer;
    transition: background 0.5s;
}

.button-content-modal button.confirm {
    background-color: #0A5E51;
}

.button-content-modal button.confirm:hover {
    background-color: #09574c;
}

.button-content-modal button.cancel {
    background-color: #F80000;
}

.button-content-modal button.cancel:hover {
    background-color: #C33535;
}
.home-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: flex-start;
    align-items: flex-start;
    
}

.home-container ul {
    list-style-type: none;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.home-container ul li {
    display: block;
    width: 300px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    margin: 30px;
}

.thumbnail {
    border-radius: 4px;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
}

.post-list-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-options {
    display: none;
    flex: 1 1;
    border-radius: 4px;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    background-color:  rgba(0, 0, 0, 0.5);
}

#post-list-title {
    color: #000;
}

#post-list-title:hover {
    text-decoration: underline;
}

a {
    text-decoration: none;
}

.main-confirm-messege {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color:lightgreen;
    width: 100%;
    margin-top: 1px;
    border: 1px dotted #FFF;
}

#new-post-button {
    display: flex;
    flex: 1 1;
    width: 95%;
    margin-top: 40px;
    justify-content: flex-end;
    align-items: center;
    color: #000;
}

.header-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 25px;
    margin-left: 25px;
    background-color: #0A5E51;
}

#new-post-button svg {
    background-color: #0A5E51
}

#new-post-button svg:hover {
    background-color: #09574c
}

#search {
    width: 40%;
    height: 35px;
    padding: 0 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
}

#search:focus {
    outline: none;
}

.search-container {
    display: flex;
    flex: 1 1;
    background-color: #FFF;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 150px;
}

.btn-form-search {
    display: inline-block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    margin-top: 3.5px;
}

.btn-form-search:hover,
.btn-form-search:focus {
    outline: none;
}
.post-container{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin: 10px 40px
}

#post { 
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.post-container h1{
    margin-bottom: 70px;
}

.answer-container {
    display: none;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
}

.coment-status {
    padding-top: 10px;
}

#answer {
    height: 40px;
    padding: 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 50%;
    font-size: 13px;
}

#answer:focus {
    outline: none;
}

.btn-form-answer {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    width: 10%;
    margin-top: 10px;
    padding: 8px 0;
    background-color: rgb(10, 94, 81);
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-weight: bold;
}

.btn-form-answer:hover {
    outline: none;
    background-color: rgb(8, 77, 66);
    cursor: pointer;
}

.btn-form-answer:focus {
    outline: none;
}

#confirm-answer {
    display: none;
}

.comments-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
}

.btn-comments-container {
    display: flex;
}

.icon-comment-container {
    color: #FFF;
    size: 14px;
    cursor: pointer;
    height: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #0A5E51;
}

.comment-icon:hover {
    color: #109783
}

.like-icon:hover {
    color: #dd3838
}

.trash-icon:hover {
    color: #0c0101
}
#modal-container-gallery {
    display: none;
    flex: 1 1;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color:  rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.modal-content-gallery {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: #FFF;
    margin: 0px 35%;
    padding: 10px 20px;
    box-shadow: 0px 2px 8px #888888;
}

#modal-container-gallery p {
    border-bottom: 1px solid rgb(196, 186, 186);
    padding-bottom: 10px;
}

.button-content-modal-gallery {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}

.button-content-modal-gallery button {
    border: 1px solid #000;
    background: transparent;
    border-radius: 4px;
    font-weight: bold;
    color: #FFF;
    width: 45%;
    height: 40px;
    cursor: pointer;
    transition: background 0.5s;
}

.button-content-modal-gallery button.confirm-gallery {
    background-color: #0A5E51;
}

.button-content-modal-gallery button.confirm-gallery:hover {
    background-color: #09574c;
}

.button-content-modal-gallery button.cancel-gallery {
    background-color: #F80000;
}

.button-content-modal-gallery button.cancel-gallery:hover {
    background-color: #C33535;
}
.gallery-container {
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

#header-gallery {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-top: 1%;
}

.dropzone-gallery {
    width: 100%;
    height: 120px;
}

.gallery-content {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center; 
}

.gallery-content ul {
    list-style-type: none;
    display: flex;
    flex: 1 1;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery-content ul li {
    display: flex;
    width: 300px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    margin: 30px;
}

.preview {
    display: flex;
    border-radius: 4px;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}
body {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body, textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  height: 100%;
}

